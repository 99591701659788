import { sanitiseRuntimeConfig } from 'runtime/runtime.config';
import merge from 'lodash.merge';
import constants from 'util/constants';
import { IOSGIConfig } from 'interfaces';

export const runtimeConfigs = (
	config: IOSGIConfig['runtimeConfig'],
	overrideConfig: IOSGIConfig['runtimeConfig']
): IOSGIConfig['runtimeConfig'] => {
	return sanitiseRuntimeConfig({
		authConfig: {
			environment:
				overrideConfig?.authConfig?.environment ??
				config?.authConfig?.environment,
			isSeed: config?.authConfig?.isSeed,
			external: {
				grantType:
					overrideConfig?.authConfig?.external?.grantType ??
					config?.authConfig?.external?.grantType,
				scope:
					overrideConfig?.authConfig?.external?.scope ??
					config?.authConfig?.external?.scope,
			},
			internal: {
				grantType:
					overrideConfig?.authConfig?.internal?.grantType ??
					config?.authConfig?.internal?.grantType,
				scope:
					overrideConfig?.authConfig?.internal?.scope ??
					config?.authConfig?.internal?.scope,
			},
		},
		authStrategiesConfig: {
			isAuthRequired:
				overrideConfig?.authStrategiesConfig?.isAuthRequired ??
				config?.authStrategiesConfig?.isAuthRequired,
			external: {
				clientId:
					overrideConfig?.authStrategiesConfig?.external?.clientId ??
					config?.authStrategiesConfig?.external?.clientId,
				dafEndpoint:
					(overrideConfig?.authStrategiesConfig?.external?.dafEndpoint ??
					config?.authStrategiesConfig?.external?.dafEndpoint) +
						constants.IDP_OAUTH_TOKEN_ENDPOINT,
			},
			internal: {
				clientId:
					overrideConfig?.authStrategiesConfig?.internal?.clientId ??
					config?.authStrategiesConfig?.internal?.clientId,
				dafEndpoint:
					(overrideConfig?.authStrategiesConfig?.internal?.dafEndpoint ??
					config?.authStrategiesConfig?.internal?.dafEndpoint) +
						constants.IDM_OAUTH_TOKEN_ENDPOINT,
			},
		},
		userConfig: overrideConfig?.userConfig,
		sessionId: overrideConfig?.sessionId,
		servicesConfig: {
			rum: overrideConfig?.servicesConfig?.rum,
			dataSecurity: merge(
				{
					bc: {
						LibraryUrl: config?.servicesConfig?.dataSecurity?.bc?.LibraryUrl,
					},
					gbd: {
						LibraryUrl: config?.servicesConfig?.dataSecurity?.gbd?.LibraryUrl,
					},
					iv: {
						LibraryUrl: config?.servicesConfig?.dataSecurity?.iv?.LibraryUrl,
					},
				},
				overrideConfig?.servicesConfig?.dataSecurity,
			),
			interaction: {
				endpoint: config?.servicesConfig?.interaction?.endpoint,
			},
			history: overrideConfig?.servicesConfig?.history,
			flags: merge(
				{
					launchDarklyKey: config?.servicesConfig?.flags?.launchDarklyKey,
				},
				overrideConfig?.servicesConfig?.flags,
			),
		},
	});
};
