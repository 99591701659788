import constants from '../../util/constants';
import merge from 'lodash.merge';

import { AnalyticsMgr, DigitalDataStrategy } from '@nab/nab-x-sdk-browser';
import { ServicesConfig } from '@nab/x-types';

const getAnalyticsPageConfig = () => {
	const defaultConfig = {
		pageName: window.document.title,
		site: window.location.href,
	};

	const urlParams = new URLSearchParams(window.location.search);
	const ssc = urlParams.get(constants.URL_FRAGMENT_SUPER_SECRET_CODE);
	const user = ssc ? { user: { ssc: ssc } } : {};

	return merge(defaultConfig, user);
};

export const getAnalyticsConfig = (): Partial<ServicesConfig> => {
	const DDS = new DigitalDataStrategy();
	const analytics = new AnalyticsMgr(DDS);
	analytics.initPage(getAnalyticsPageConfig());
	return {
		analytics: {
			strategy: DDS,
		},
	};
};
