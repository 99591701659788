import * as yup from 'yup';
import type { Asserts } from 'yup';

export const InputConfigParser = yup.object().shape({
	abandonFormNotification: yup.string().oneOf(['true', 'false']),
	appConfig: yup.string().nullable(),
	appType: yup.string().oneOf(['XV2']),
	authenticationRequired: yup.string().oneOf(['true', 'false']),
	bioCatchLib: yup.string().nullable(),
	cf: yup.string().nullable(),
	clearTokenInit: yup.boolean(),
	cookieNotification: yup.string().oneOf(['true', 'false']),
	cookieNotificationMsg: yup.string().nullable(),
	disableKeepAlive: yup.string().oneOf(['true', 'false']),
	errorPageMsg: yup.string().nullable(),
	errorPageTitle: yup.string().nullable(),
	experienceFragments: yup.array().of(yup.string()),
	fraudInteractionEndpoint: yup.string().nullable(),
	getBrowserDataLib: yup.string().nullable(),
	hostName: yup.string(),
	iovationLib: yup.string().nullable(),
	maintenanceMode: yup.string().oneOf(['true', 'false']),
	maintenanceModeButtonLabel: yup.string().nullable(),
	maintenanceModeButtonLink: yup.string().nullable(),
	maintenanceModeMsg: yup.string().nullable(),
	maintenanceModeTitle: yup.string().nullable(),
	manifest: yup.string(),
	renderIdentityAppAsLogin: yup.boolean(),
	renderLogin: yup.boolean(),
	requestTokenOnLoad: yup.boolean(),
	sessionTONotification: yup.string().oneOf(['true', 'false']),
	sessionTONotificationMsg: yup.string().nullable(),
	tag: yup.string(),

	identityAppConfig: yup.object({
		identityMiniAppTag: yup.string(),
		appType: yup.string(),
		identityMiniAppUrl: yup.string(),

		appConfig: yup.object({
			clientId: yup.string(),
			environment: yup.string(),
			scope: yup.string(),
			tenant: yup.string(),
			headerText: yup.object({
				superTitle: yup.string(),
				title: yup.string(),
			}).noUnknown(),
		}),

		dataSecurity: yup.object({
			iv: yup.object({
				LibraryUrl: yup.string().url(),
			}).noUnknown(),
			bc: yup.object({
				LibraryUrl: yup.string().url(),
			}).noUnknown(),
			gbd: yup.object({
				LibraryUrl: yup.string().url(),
			}).noUnknown()
		}).noUnknown(),
	}).noUnknown(),

	shellConfig: yup.object({
		scope: yup.string(),
		grantType: yup.string(),
		kongClientId: yup.string(),
		kongEsgEndPoint: yup.string(),
		skipTokenExchange: yup.boolean(),
		launchDarlyKey: yup.string(),
		environment: yup.string(),
		debug: yup.string().oneOf(['true', 'false']),

		keepAlive: yup.object({
			idleTime: yup.number(),
		}).noUnknown(),

		internal: yup.object({
			environment: yup.string(),
			kongClientId: yup.string(),
			grantType: yup.string(),
			scope: yup.string(),
			dafEndPoint: yup.string(),
		}).noUnknown(),
	}).noUnknown(),

	ssoConfig: yup.object({
		clientId: yup.string(),
		scopes: yup.string(),
		ssoEnabled: yup.string().oneOf(['true', 'false']),
		isSecureSite: yup.string().oneOf(['true', 'false']),
		displayStrategy: yup.string(),
		redirectUri: yup.string().nullable(),
		serviceId: yup.string(),
	}).noUnknown(),
}).noUnknown();

export interface InputConfigSchema extends Asserts<typeof InputConfigParser> {};
