import { retrieveJson } from 'util/retrieve-json';
import { errorsConfigs } from './error-screen-config';
import { identityAppConfigs } from './identity-app-config';
import { keepAliveConfigs } from './keep-alive-config';
import { miniAppConfigs } from './miniapp-config';
import { runtimeConfigs } from './runtime-config';
import { shellConfigs } from './shell-config';
import { IOSGIConfig } from '../interfaces';
import { OSGIConfigParser, OSGIConfigSchema } from '../schemas/osgi-config-schema';
import { safeSchemaParse } from 'util/safe-schema-parse';

export const buildConfigs = async (OSGIConfigs: IOSGIConfig): Promise<IOSGIConfig> => {
	const obj = OSGIConfigs?.shellConfig?.appConfig
		? await retrieveJson(OSGIConfigs?.shellConfig?.appConfig)
		: {};
	const overrideConfig = safeSchemaParse<OSGIConfigSchema>(obj, OSGIConfigParser);

	return {
		errorsConfig: errorsConfigs(
			OSGIConfigs.errorsConfig,
			overrideConfig.errorsConfig,
		),
		identityAppConfig: identityAppConfigs(
			OSGIConfigs.identityAppConfig,
			overrideConfig.identityAppConfig,
		),
		keepAliveConfig: keepAliveConfigs(
			OSGIConfigs.keepAliveConfig,
			overrideConfig?.keepAliveConfig,
		),
		miniappConfig: miniAppConfigs(
			OSGIConfigs.miniappConfig,
			overrideConfig.miniappConfig,
		),
		runtimeConfig: runtimeConfigs(
			OSGIConfigs.runtimeConfig,
			overrideConfig.runtimeConfig,
		),
		shellConfig: shellConfigs(OSGIConfigs.shellConfig),
	};
};
