import styled from 'styled-components';

export const StyledModalContent = styled.div`
	& nab-idp-password,
	& nab-idp-usernamepassword-tokencode {
		border-radius: 8px;
		overflow: hidden;
		display: block;
	}
`;
