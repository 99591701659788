import {
  customElement,
  LitElement,
  property,
  PropertyValues,
} from "lit-element";
import { configPropParser } from "../util/config-prop-parser";
import { loadEmbeddedApp } from "./EmbeddedApp";
import constants from "../util/constants";
import MiniAppContainer from "./MiniAppContainer/MiniAppContainer";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { GlobalShadowStyle, GlobalStyle, Theme } from "@nab/nui-react";
import ErrorScreen from "./ErrorsScreen/ErrorsScreen";
import IdentityApp from "./IdentityApp/IdentityApp";
import { init } from "../util/init";
import { sessionKeepAlive } from "util/session-keep-alive";
import { buildConfigs } from "config-builders";
import { IOSGIConfig } from "interfaces";

/**
 * This custom element will load a mini-app inside itself once inserted in to the DOM
 *
 * Config can be supplied in a serialised string in the 'config' attribute
 *
 * <mini-app-loader config='{ "tag": "foobar-app", "manifest": "http://nab.foo/asset-manifest.json" }'></mini-app-loader>
 */
@customElement("mini-app-loader")
export class MiniAppLoader extends LitElement {
  @property({ type: Object, attribute: "config", converter: configPropParser })
  public config: IOSGIConfig;

  @property({ type: String, attribute: "x-mini-app-id", reflect: true })
  public miniAppId: string;

  @property({ type: Boolean, attribute: "x-mini-app-loaded", reflect: true })
  public loaded: boolean;

  @property({ type: String, attribute: "auth", reflect: true })
  public auth: string;

  protected loadInitiated: boolean = false;

  public async updated(changedProps: PropertyValues) {
    if ((changedProps.size || !this.loadInitiated) && this.config) {
      this.renderMiniApp();
    }
  }

  public async connectedCallback() {
    super.connectedCallback();
    if (this.config && !this.loadInitiated) {
      const nabShellScript = document.querySelector("mini-app-loader");
      if (nabShellScript && (nabShellScript as any).nextElementSibling) {
        const MANIFEST_ADDRESS = (
          nabShellScript as any
        ).nextElementSibling.src.match(/^(.*)index.js/)[1];
        // eslint-disable-next-line no-undef
        __webpack_public_path__ = MANIFEST_ADDRESS; // eslint-disable-line camelcase
      } else {
        const script = document.querySelector("main")?.nextElementSibling;
        if (script) {
          const MANIFEST_ADDRESS = (script as any).src.match(
            /^(.*)index.js/,
          )[1];
          // eslint-disable-next-line no-undef
          var __webpack_public_path__ = MANIFEST_ADDRESS; // eslint-disable-line camelcase
        }
      }
      this.renderMiniApp();
    }
  }

  /**
   * Loads the mini-app using configurations obtained from AEM.
   *
   * Retrieves the content fragment for the mini-app which will include values used to populate authorable properties
   * of the mini-app. This will be set as a property "appConfig" on the mini-app host element.
   *
   * Set the API configurations based on the current environment, uses default values when configurations are not
   * supplied through the elements attribute from AEM.
   */
  protected async renderMiniApp() {
    if (this.loadInitiated) {
      return;
    }
    this.loadInitiated = true;

    const config = await buildConfigs({ ...this.config });

    const { shellActions, shellServices } = await init(config);
    this.miniAppId = config.miniappConfig.appMetaData.tag;

    const keepAlive = sessionKeepAlive(config);

    const App: React.FunctionComponent<{ appConfig: any; renderRoot: any }> =
      function ({ appConfig: any, renderRoot }) {
        const handleCSIDUpdated = (event: any) => {
          shellServices.dataSecurityMgr.bc.updateData(event.csid);
        };

        useEffect(() => {
          if (!config.keepAliveConfig.disableKeepAlive) {
            keepAlive.init();
          }
          return () => {
            if (!config.keepAliveConfig.disableKeepAlive) {
              keepAlive.destroy();
            }
          };
        }, []);

        useEffect(() => {
          // Listening for csid update event
          shellActions.addEventListener(
            constants.EVENT_CSID_UPDATED,
            handleCSIDUpdated,
          );
        });

        return (
          <>
            <GlobalStyle />
            <MiniAppContainer renderRoot={renderRoot}>
              <Theme>
                <GlobalShadowStyle borderBox={true} normalize={true} />
                <ErrorScreen config={config.errorsConfig} />
                <IdentityApp config={config.identityAppConfig} />
              </Theme>
            </MiniAppContainer>
          </>
        );
      };

    // This config is just to customize the attributes which are mandatory

    if (!customElements.get(constants.APP_SHELL_TAG)) {
      const element: any = document.createElement(constants.APP_SHELL_TAG);
      element.appConfig = config;
      element.user = {};
      element.actions = {};
      this.appendChild(element);
      ReactDOM.render(
        <App appConfig={config} renderRoot={this.renderRoot as any} />,
        element,
      );
    }

    if (true === config?.shellConfig?.renderLogin) {
      await loadEmbeddedApp(config.identityAppConfig, this);
    } else if (true !== config?.errorsConfig?.maintenance?.active) {
      loadEmbeddedApp(config.miniappConfig, this);
    }
  }

  public async disconnectedCallback() {
    super.disconnectedCallback();
  }

  createRenderRoot() {
    return this;
  }
}
