import React, { useState, useRef, useEffect } from "react";
import parse from "html-react-parser";
import constants from "../../util/constants";
import { getShellActions } from "@nab/x-runtime";
import { Button, H1, H2, Modal, Paragraph } from "@nab/nui-react";
import {
  ErrorBanner,
  ErrorScreenContainer,
  IconWrapper,
  ModalDataContainer,
} from "./ErrorsScreen.style";
import ErrorIcon from "./ErrorIcon";
import { refreshPage } from "util/reload-page";

const ErrorScreen: React.FunctionComponent<{ config }> = ({ config }) => {
  const appActions = getShellActions();
  const [showError, setShowError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showCookieDisabledContent, setShowCookieDisabledContent] =
    useState(false);
  const modelReference = useRef(null);

  const handleLoadEvent = (event: any) => {
    if (event && !event.appLoaded) {
      setShowError(true);
    }
    setShowCookieDisabledContent(navigator && !navigator.cookieEnabled);
  };

  const handleKeepAlive = (event: any) => {
    setShowModal(true);
  };

  const handleMiniappError = (event: any) => {
    if (event?.miniappError) {
      setShowError(true);
    }
  };

  const displayTitle = (content: String) => {
    if (document.getElementsByTagName("h1").length === 0) {
      return <H1>{content}</H1>;
    }
    return <H2>{content}</H2>;
  };

  useEffect(() => {
    //Listening for app load
    appActions.addEventListener(
      constants.APP_SHELL_LOADED_EVENT,
      handleLoadEvent,
    );

    //Listening for miniapp Error event
    appActions.addEventListener(
      constants.MINIAPP_ERROR_EVENT,
      handleMiniappError,
    );

    //Listening for the app inactive event
    appActions.addEventListener(
      constants.APP_SHELL_KEEP_ALIVE_USER_INACTIVE,
      handleKeepAlive,
    );
  }, []);

  const errorContent = ({ title, message, button }) => (
    <>
      <ErrorScreenContainer>
        <ErrorBanner>
          <IconWrapper>
            <ErrorIcon />
          </IconWrapper>
          {displayTitle(title)}
          <Paragraph>{parse(message)}</Paragraph>
          {button?.label && button?.link && (
            <Button
              type="button"
              variant="primary"
              label={button.label}
              onClick={() => {
                location.href = button.link;
              }}
            />
          )}
        </ErrorBanner>
      </ErrorScreenContainer>
    </>
  );

  const notificationModel = ({ alignment, button, message, title }) => {
    <>
      <Modal
        rootNode={modelReference.current}
        id={"id"}
        title={title}
        alignment={alignment}
      >
        <ModalDataContainer>
          <Paragraph>{message}</Paragraph>
          {button?.label && button?.link && (
            <Button
              type="button"
              variant="primary"
              label={button.label}
              onClick={() => refreshPage()}
            />
          )}
        </ModalDataContainer>
      </Modal>
    </>;
  };

  return (
    <>
      <div ref={modelReference}>
        <>
          {config?.maintenance?.active && errorContent(config.maintenance)}

          {!config?.maintenance?.active &&
            showError &&
            errorContent(config.error)}

          {config?.sessionTimeout?.active &&
            showModal &&
            notificationModel(config.sessionTimeout)}

          {config?.cookies?.DisabledNotification &&
            showCookieDisabledContent &&
            notificationModel(config.cookies)}
        </>
      </div>
    </>
  );
};

export default ErrorScreen;
