import merge from 'lodash.merge';
import { userIsInternal } from '../../util/user-is-internal';

export const sanitiseAuthConfig = (config) => {
	const runtimeAuthConfig = {
		authConfig: {
			environment: config?.environment,
			tokenType: 'Bearer',
			tokenSource: 'DAF',
			isSeed: config?.isSeed,
			requestTokenOnLoad: config?.requestTokenOnLoad ?? true,
		},
	};
	const externalConfig = {
		authConfig: {
			context: 'external',
			grantType: config?.external?.grantType,
			scopes: [config?.external?.scope],
		},
	};
	const internalConfig = {
		authConfig: {
			context: 'internal',
			grantType: config?.internal?.grantType,
			scopes: [config?.internal?.scope],
		},
	};
	return merge(
		runtimeAuthConfig,
		userIsInternal() ? internalConfig : externalConfig,
	);
};
