import constants from 'util/constants';

import { LaunchDarklyFlagStrategy } from '@nab/nab-x-sdk-browser';
import { ServicesConfig } from '@nab/x-types';

export const getFlagStrategyConfig = (config): Partial<ServicesConfig> => {
	return config?.launchDarklyKey
		? {
				flags: {
					strategy: new LaunchDarklyFlagStrategy(config?.launchDarklyKey),
					apiKey: config?.apiKey ?? constants.DEFAULT_LAUNCHDARKLY_KEY,
					initialData: config?.initialData ?? {},
				},
		  }
		: {};
};
