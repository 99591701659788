import { IOSGIConfig } from '../interfaces';

export const keepAliveConfigs = (config: IOSGIConfig['keepAliveConfig'], overrideConfig: IOSGIConfig['keepAliveConfig']) => {
	return {
		endpoint: overrideConfig?.endpoint ?? config.endpoint,
		disableKeepAlive:
			overrideConfig?.disableKeepAlive ?? config?.disableKeepAlive,
		idleTime: overrideConfig?.idleTime ?? config.idleTime,
	};
};
