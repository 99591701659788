import { AppDRUMStrategy } from '@nab/nab-x-sdk-browser';
import { ServicesConfig } from '@nab/x-types';

export const getAppDConfig = (config): Partial<ServicesConfig> => {
	const rumConfig = {
		libraryUrl:
			config?.libraryUrl ??
			'https://x.nab.com.au/static-shared/nab/js/appd/adrum-23.3.0.4265.js',
		configValue: {
			appKey: config?.configValue?.appKey ?? '',
			adrumExtUrlHttp:
				config?.configValue?.adrumExtUrlHttp ?? 'http://cdn.appdynamics.com',
			adrumExtUrlHttps:
				config?.configValue?.adrumExtUrlHttps ?? 'https://cdn.appdynamics.com',
			beaconUrlHttp:
				config?.configValue?.beaconUrlHttp ??
				'http://syd-col.eum-appdynamics.com',
			beaconUrlHttps:
				config?.configValue?.beaconUrlHttps ??
				'https://syd-col.eum-appdynamics.com',
			xd: {
				enable: config?.configValue?.xd?.enable ?? true,
			},
			spa: {
				spa2: config?.configValue?.spa?.spa2 ?? true,
			},
		},
	};
	return config?.configValue?.appKey
		? {
				rum: {
					strategy: new AppDRUMStrategy(rumConfig),
				},
		  }
		: {};
};
