import { removeUndefinedAttributes } from 'util/remove-undefined-attributes';
import { IOSGIConfig } from 'interfaces';

export const errorsConfigs = (config: IOSGIConfig['errorsConfig'], overrideConfig: IOSGIConfig['errorsConfig']) => {
	return removeUndefinedAttributes({
		error: {
			title:
				overrideConfig?.error?.title ??
				config?.error?.title ??
				'Something went wrong',
			message:
				overrideConfig?.error?.message ??
				config?.error?.message ??
				"We couldn't load this page. Please try again later.",
		},
		maintenance: {
			title: overrideConfig?.maintenance?.title ?? config?.maintenance?.title,
			message:
				overrideConfig?.maintenance?.message ?? config?.maintenance?.message,
			button: {
				label:
					overrideConfig?.maintenance?.button?.label ??
					config?.maintenance?.button?.label,
				link:
					overrideConfig?.maintenance?.button?.link ??
					config?.maintenance?.button?.link,
			},
			active:
				overrideConfig?.maintenance?.active ??
				config?.maintenance?.active ??
				false,
		},
		sessionTimeout: {
			title:
				overrideConfig?.sessionTimeout?.title ?? 'Your session has expired',
			message:
				overrideConfig?.sessionTimeout?.message ??
				config?.sessionTimeout?.message,
			alignment: overrideConfig?.sessionTimeout?.alignment ?? 'center',
			button: {
				label: overrideConfig?.sessionTimeout?.button?.label ?? 'Start again',
			},
			active:
				overrideConfig?.sessionTimeout?.active ??
				config?.sessionTimeout?.active ??
				false,
		},
		cookies: {
			title: overrideConfig?.cookies?.title ?? 'Browser cookies disabled',
			message:
				overrideConfig?.cookies?.message ?? config?.cookies?.message ?? '',
			alignment: overrideConfig?.cookies?.alignment ?? 'center',
			button: {
				label:
					overrideConfig?.cookies?.button?.label ??
					'Proceed after enabling cookies',
			},
			active:
				overrideConfig?.cookies?.active ?? config?.cookies?.active ?? false,
		},
	});
};
