import local from '../config/env.local.json';
import merge from 'lodash.merge';
import { removeUndefinedAttributes } from 'util/remove-undefined-attributes';
import { InputConfigSchema } from '../schemas/input-config-schema';
import { OSGIConfigParser, OSGIConfigSchema } from '../schemas/osgi-config-schema';
import { IOSGIConfig } from '../interfaces';
import { safeSchemaParse } from 'util/safe-schema-parse';

export const OSGIConfigs = (config: InputConfigSchema): IOSGIConfig => {
  const OSGIConfig: IOSGIConfig = {
    errorsConfig: {
      error: {
        title: config?.errorPageTitle,
        message: config?.errorPageMsg,
      },
      maintenance: {
        title: config?.maintenanceModeTitle,
        message: config?.maintenanceModeMsg,
        button: {
          label: config?.maintenanceModeButtonLabel,
          link: config?.maintenanceModeButtonLink,
        },
        active: 'true' === config?.maintenanceMode,
      },
      sessionTimeout: {
        message: config?.sessionTONotificationMsg,
        active: 'true' === config?.sessionTONotification,
      },
      cookies: {
        message: config?.cookieNotificationMsg,
        active: 'true' === config?.cookieNotification,
      },
    },
    identityAppConfig: {
      appMetaData: {
        id: config?.identityAppConfig?.identityMiniAppTag,
        appType: config?.identityAppConfig?.appType,
        tag: config?.identityAppConfig?.identityMiniAppTag,
        assetManifestUrl: config?.identityAppConfig?.identityMiniAppUrl,
      },
      appConfig: {
        clientId: config?.identityAppConfig?.appConfig?.clientId,
        environment: config?.identityAppConfig?.appConfig?.environment,
        scope: config?.identityAppConfig?.appConfig?.scope,
        tenant: config?.identityAppConfig?.appConfig?.tenant,
        superTitle:
          config?.identityAppConfig?.appConfig?.headerText?.superTitle,
        title: config?.identityAppConfig?.appConfig?.headerText?.title,
        redirectByShell: false,
      },
    },
    keepAliveConfig: {
      endpoint: config?.shellConfig?.kongEsgEndPoint,
      disableKeepAlive: 'true' === config.disableKeepAlive,
      idleTime: config?.shellConfig?.keepAlive?.idleTime,
    },
    miniappConfig: {
      appMetaData: {
        id: config?.tag,
        appType: config?.appType,
        tag: config?.tag,
        assetManifestUrl: config?.manifest,
      },
      appConfig: {
        cf: config?.cf,
        experienceFragments: config?.experienceFragments,
      },
    },
    runtimeConfig: {
      authConfig: {
        environment: config?.identityAppConfig?.appConfig?.environment,
        requestTokenOnLoad: config?.requestTokenOnLoad,
        isSeed: config?.shellConfig?.skipTokenExchange,
        external: {
          grantType: config?.shellConfig?.grantType,
          scope: config?.shellConfig?.scope,
        },
        internal: {
          grantType: config?.shellConfig?.grantType,
          scope: config?.shellConfig?.scope,
        },
      },
      authStrategiesConfig: {
        isAuthRequired: 'true' === config.authenticationRequired,
        external: {
          clientId: config?.identityAppConfig?.appConfig?.clientId,
          dafEndpoint: config?.shellConfig?.kongEsgEndPoint,
        },
        internal: {
          clientId: config?.identityAppConfig?.appConfig?.clientId,
          dafEndpoint: config?.shellConfig?.kongEsgEndPoint,
        },
      },
      servicesConfig: {
        dataSecurity: {
          bc: {
            LibraryUrl: config?.bioCatchLib,
          },
          gbd: {
            LibraryUrl: config?.getBrowserDataLib,
          },
          iv: {
            LibraryUrl: config?.iovationLib,
          },
        },
        interaction: {
          endpoint: config?.fraudInteractionEndpoint,
        },
        flags: {
          launchDarklyKey: config?.shellConfig?.launchDarlyKey,
        },
      },
    },
    shellConfig: {
      abandonFormNotification: 'true' === config?.abandonFormNotification,
      clearTokenOnInit: config?.clearTokenInit,
      contentFragment: config?.cf,
      appConfig: config?.appConfig,
      renderLogin: config?.renderLogin,
    },
  };
  return removeUndefinedAttributes(
    merge(
      getDefaultOSGIConfig(config?.shellConfig?.environment) ?? {},
      OSGIConfig
    )
  );
};

const getDefaultOSGIConfig = (env: string): IOSGIConfig => {
  if (env === 'local') {
    return safeSchemaParse<OSGIConfigSchema>(local, OSGIConfigParser);
  }
};
