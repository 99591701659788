const constants = {
	BEARER_TOKEN: 'bearerToken',
	ANONYMOUS_TOKEN: 'anonymousToken',
	STANDALONE_EXCHANGE_TOKEN_TYPE:
		'urn:ietf:params:oauth:token-type:access_token',
	STANDALONE_EXCHANGE_SCOPE: 'idp:user',
	DAF_MAX_RETRY_COUNT: 1,
	TOKEN_EXCHANGE_GRANT_TYPE: 'urn:ietf:params:oauth:grant-type:token-exchange',
	TENANT_NABC: 'nabc',

	IDP_OAUTH_TOKEN_ENDPOINT: '/v1/idp/oauth/token',
	IDM_OAUTH_TOKEN_ENDPOINT: '/v1/idm/oauth/token',
	NABX_CHANNEL_ENDPOINT: '/v1/nabx/channel',

	EVENT_USER_AUTHENTICATED: 'nabx:user_authenticated',
	EVENT_USER_CANCELLED: 'nabx:user_cancelled',
	EVENT_USER_AUTHENTICATED_XV2: 'user_authenticated',
	EVENT_USER_CANCELLED_XV2: 'user_cancelled',
	EVENT_LOGIN: 'nabx:login',
	EVENT_WINDOW_BEFORE_UNLOAD: 'beforeunload',
	EVENT_DISPLAY_MESSAGE: 'display_message',
	EVENT_CLEAR_USER_DATA: 'clear_user_data',
	EVENT_CSID_UPDATED: 'csid_updated',

	APP_NAME_IDENTITY: 'identity',
	IDENTITY_MINI_APP_ROOT: 'identity-mini-app',

	OAUTH_KEEP_ALIVE_ENDPOINT: '/v1/forms/session/heartbeat',

	APP_SHELL_TAG: 'nab-x-shell',
	APP_SHELL_LOADED_EVENT: 'nab-x-app-loaded',
	APP_SHELL_KEEP_ALIVE_USER_INACTIVE: 'keep-alive-user-inactive',

	MINIAPP_ERROR_EVENT: 'miniapp-error',

	KEY_DOWN: 'keydown',

	SEED_TOKEN_STORGATE_KEY: 'seedToken',
	AUTHENTICATED_2FA_TOKEN: 'reauthToken',

	TOKEN_IN_URL: 'token',
	DEFAULT_LAUNCHDARKLY_KEY: '599a6e8eed01b30b18e1921c',

	USERCONTEXT: 'userContext',
	DEFAULT_USERCONTEXT_VALUE: 'external',
	USERCONTEXT_EXTERNAL: 'external',
	USERCONTEXT_INTERNAL: 'internal',

	// The url constants should be removed, replaced by config passed into shell. Version 4 will remove all single app specific use cases.
	URL_PARAM_BAGNUMBER: 'bagNumber',
	URL_FRAGMENT_SUPER_SECRET_CODE: 'ssc',
};

export default constants;
