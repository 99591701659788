import { OSGIConfigs } from 'config-builders/OSGI-config';
import { InputConfigParser, InputConfigSchema } from 'schemas/input-config-schema';
import { IOSGIConfig } from 'interfaces';
import { safeJsonParse } from './safe-json-parse';
import { safeSchemaParse } from './safe-schema-parse';

/**
 * Transformer for LitElement props which are of Object type and also mapped to attributes
 *
 * Will parse a stringified JSON attribute value for use as a prop and vice versa
 */
export const configPropParser = {
	fromAttribute: (input: string | Object): IOSGIConfig => {
		try {
			const object: any = safeJsonParse(input);
			object.identityAppConfig = safeJsonParse(object.identityAppConfig);
			object.shellConfig = safeJsonParse(object.shellConfig);
			object.ssoConfig = safeJsonParse(object.ssoConfig);

			const config = safeSchemaParse<InputConfigSchema>(object, InputConfigParser);
			return OSGIConfigs(config);
		} catch (x) {
			console.error(x);
		}
	},
	toAttribute: (prop: string | object): string => {
		return typeof prop === 'object' && prop
			? JSON.stringify(prop)
			: <string>prop;
	},
};
