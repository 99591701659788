import axios from "axios";
import constants from "./constants";
import { getTokenFromHash } from "./hash-fragment-token";
import { errorMessage } from "./unknown-error";
import { DafAuthResponse, DafGrantType } from "@nab/x-runtime";

export const checkHashTokenValidity = async (
  token: string,
  { clientId, dafEndpoint },
) => {
  try {
    const response = await axios.post<DafAuthResponse>(dafEndpoint, {
      client_id: clientId,
      grant_type: DafGrantType.tokenExchange,
      requested_token_type: constants.STANDALONE_EXCHANGE_TOKEN_TYPE,
      subject_token: token,
      subject_token_type: constants.STANDALONE_EXCHANGE_TOKEN_TYPE,
      scope: constants.STANDALONE_EXCHANGE_SCOPE,
    });
    response.status === 200 && replaceBearerToken(token);
  } catch (e) {
    console.log(errorMessage(e));
  }
};

const replaceBearerToken = (token) => {
  window.sessionStorage.removeItem(constants.BEARER_TOKEN);
  window.sessionStorage.setItem(constants.BEARER_TOKEN, token);
};
