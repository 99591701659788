import { getParams } from 'util/get-params';
import { removeUndefinedAttributes } from 'util/remove-undefined-attributes';
import { IOSGIConfig } from '../interfaces';

export const miniAppConfigs = (
	config: IOSGIConfig['miniappConfig'],
	overrideConfig: IOSGIConfig['miniappConfig']
): IOSGIConfig['miniappConfig'] => {
	return removeUndefinedAttributes({
		appMetaData: {
			id: config?.appMetaData?.tag,
			appType: config?.appMetaData?.appType,
			tag: config?.appMetaData?.tag,
			assetManifestUrl: config?.appMetaData?.assetManifestUrl,
		},
		params: getParams(),
		appConfig: {
			cf: config?.appConfig?.cf,
			experienceFragments: config?.appConfig?.experienceFragments
		},
		appData: overrideConfig?.appData ?? {},
	});
};
