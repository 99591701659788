import { initRuntime } from '@nab/x-runtime';
import { IDigitalData } from '@nab/x-types';
import { checkHashTokenValidity } from './check-token-validity';
import { IOSGIConfig, IWindowADRUM, IWindowShell } from '../interfaces';
import { setShellVersion } from './set-shell-version';
import { clearTokenOnInit } from './clear-token-on-init';
import { abandonFormNotification } from './abandon-form-notification';
import { getTokenFromHash } from './hash-fragment-token';

declare global {
	interface Window {
		digitalData: IDigitalData;
		ADRUM: IWindowADRUM;
		nabshell: IWindowShell;
	}
}

let initialised: boolean = false;

/**
 * Initialises the x-runtime
 *
 * - Assumes that the same environment is used by all mini-apps on a page in non-prod
 * - Sets default auth config and defines auth strategies when the first mini-app is loaded
 * - This config is inherited by all subsequent mini-apps but can be overridden if an app requires
 */
export const init = async (config: IOSGIConfig) => {
	if (initialised) {
		return;
	}
	setShellVersion('4.3.0');
	clearTokenOnInit(config.shellConfig);
	abandonFormNotification(config.shellConfig);

	const token = getTokenFromHash();
	if (token) {
		await checkHashTokenValidity(token, config.runtimeConfig.authStrategies[1]); // DAF Anonymous auth strategy
	}

	const { shellServices, shellActions } = await initRuntime(config.runtimeConfig); // add initRuntime config here
	initialised = true;
	return {
		shellServices,
		shellActions
	}
};
