import * as yup from 'yup';
import type { Asserts } from 'yup';

const ErrorsConfig = yup.object({
  error: yup.object({
    title: yup.string(),
    message: yup.string(),
  }),
  maintenance: yup.object({
    title: yup.string(),
    message: yup.string(),
    button: yup.object({
      label: yup.string(),
      link: yup.string(),
    }),
    active: yup.boolean(),
  }),
  sessionTimeout: yup.object({
    message: yup.string(),
    active: yup.boolean(),
  }),
  cookies: yup.object({
    message: yup.string(),
    active: yup.boolean(),
  }),
});

const IdentityAppConfig = yup.object({
  appMetaData: yup.object({
    id: yup.string(),
    appType: yup.string().oneOf(['XV2']),
    tag: yup.string(),
    assetManifestUrl: yup.string(),
  }),
  appConfig: yup.object({
    clientId: yup.string(),
    environment: yup.string(),
    scope: yup.string(),
    tenant: yup.string(),
    superTitle:
      yup.string(),
    title: yup.string(),
  }),
});

const KeepAliveConfig = yup.object({
  endpoint: yup.string(),
  disableKeepAlive: yup.boolean(),
  idleTime: yup.number(),
});

const RuntimeConfig = yup.object({
  authConfig: yup.object({
    environment: yup.string(),
    requestTokenOnLoad: yup.boolean(),
    isSeed: yup.boolean(),
    external: yup.object({
      grantType: yup.string(),
      scope: yup.string(),
    }),
    internal: yup.object({
      grantType: yup.string(),
      scope: yup.string(),
    }),
  }),
  authStrategiesConfig: yup.object({
    isAuthRequired: yup.boolean(),
    external: yup.object({
      clientId: yup.string(),
      dafEndpoint: yup.string(),
    }),
  }),
  dataSecurity: yup.object({
    bc: yup.object({
      LibraryUrl: yup.string(),
    }),
    gbd: yup.object({
      LibraryUrl: yup.string(),
    }),
    iv: yup.object({
      LibraryUrl: yup.string(),
    }),
  }),
  interaction: yup.object({
    endpoint: yup.string(),
  }),
  flags: yup.object({
    launchDarklyKey: yup.boolean(),
  }),
});

const ShellConfig = yup.object({
  abandonFormNotification: yup.boolean(),
  clearTokenOnInit: yup.boolean(),
  contentFragment: yup.string(),
  // appConfig: config?.appConfig,
  renderLogin: yup.boolean(),
});

export const OSGIConfigParser = yup.object().shape({
  errorsConfig: ErrorsConfig,
  identityAppConfig: IdentityAppConfig,
  keepAliveConfig: KeepAliveConfig,
  miniappConfig: yup.object({
    appMetaData: yup.object({
      id: yup.string(),
      appType: yup.string().oneOf(['XV2']),
      tag: yup.string(),
      assetManifestUrl: yup.string(),
    }),
    appConfig: yup.object({
      cf: yup.string(),
    }),
  }),
  runtimeConfig: RuntimeConfig,
  shellConfig: ShellConfig,
});

export interface OSGIConfigSchema extends Asserts<typeof OSGIConfigParser> {};
