import merge from 'lodash.merge';
import { IOSGIConfig } from 'interfaces';

export const identityAppConfigs = (
	config: IOSGIConfig['identityAppConfig'],
	overrideConfig: IOSGIConfig['identityAppConfig']
): IOSGIConfig['identityAppConfig'] => {
	return {
		appMetaData: {
			id: overrideConfig?.appMetaData?.id ?? config?.appMetaData?.id,
			appType:
				overrideConfig?.appMetaData?.appType ?? config?.appMetaData?.appType,
			tag: overrideConfig?.appMetaData?.tag ?? config?.appMetaData?.tag,
			assetManifestUrl: `${
				overrideConfig?.appMetaData?.assetManifestUrl ??
				config.appMetaData?.assetManifestUrl
			}/asset-manifest.json`,
			entryPoint: `${
				overrideConfig?.appMetaData?.tag ?? config?.appMetaData?.tag
			}.js`,
			properties:
				overrideConfig?.appMetaData?.properties ??
				config?.appMetaData?.properties,
		},
		appConfig: merge(config.appConfig, overrideConfig?.appConfig),
		appData: merge({}, overrideConfig?.appData),
	};
};
