import React from 'react';
import { StyleSheetManager } from 'styled-components';
import MiniAppContext from './MiniAppContext';
import { IMiniAppContainerProps } from '../../interfaces'

/**
 *
 * A MiniAppContainer is used to wrap the mini app <App /> component.
 * It provides a context to get the parent element (renderRoot),
 * which can be used to query element inside the shadow DOM tree.
 * It also adds any object passed in as props to context.
 * This container also sets style sheets to be rendered inside the renderRoot elment. *
 * @example
 * ```
 * <MiniAppContainer renderRoot={this.renderRoot} appConfig={this.appConfig} auth={this.auth}>
 *   <App />
 * </MiniAppContainer>
 * ```
 * @noInheritDoc
 */
const MiniAppContainer: React.FC<IMiniAppContainerProps> = function({ renderRoot, children, ...others }) {
    return (
        <MiniAppContext.Provider value={{ renderRoot, ...others }}>
            <StyleSheetManager target={renderRoot as any}>{children}</StyleSheetManager>
        </MiniAppContext.Provider>
    );
};

export default MiniAppContainer;
