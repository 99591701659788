import {
	DafAnonymousAuthStrategy,
	DafExchangeStrategy,
	AuthStrategy,
} from '@nab/x-runtime';
import { CustomNabCAuthStrategy } from './custom-nabc-auth-strategy';

const getAuthStrategies = (config): Array<AuthStrategy> => {
	const { clientId, dafEndpoint, isAuthRequired } = config;
	return isAuthRequired
		? ([
				new CustomNabCAuthStrategy(dafEndpoint, clientId, true),
				new DafAnonymousAuthStrategy(dafEndpoint, clientId, true),
				new DafExchangeStrategy(dafEndpoint),
		  ] as Array<AuthStrategy>)
		: [];
};

export const getAuthStrategiesRuntime = (config) => {
	return {
		authStrategies: getAuthStrategies(config),
	};
};
