export const getUserConfig = (config) => {
	return {
		userConfig: {
			id: config?.id,
			org: config?.org ?? 'NAB',
			type: config?.type ?? 'customer',
			authenticated: config?.authenticated,
			sessionId: config?.sessionId,
			details: config?.details,
			context: config?.context,
			as: config?.as,
			hashUserId: config?.hashUserId
		},
	};
};