/* eslint-disable camelcase */
import {
	dafErrorMap,
	getShellActions,
	DafAnonymousAuthConfig,
	DafAnonymousAuthStrategy,
	DafAuthError,
	DafAuthToken,
	DafFlags,
} from '@nab/x-runtime';
import constants from 'util/constants';

export interface CustomNabCAuthConfig extends DafAnonymousAuthConfig {
	flags?: {
		identityAppTag?: string;
		identityAppConfig?: any;
	} & DafFlags;
}

/**
 * DAF custom auth strategy for Nab Connect using correct tenant
 */
export class CustomNabCAuthStrategy extends DafAnonymousAuthStrategy {
	constructor(
		dafEndpoint: string,
		protected clientId: string,
		protected isSeed: boolean = true,
	) {
		super(dafEndpoint, clientId);
	}

	// Strategy will only be invoked if the tenant is nabc
	public supports(config: CustomNabCAuthConfig): boolean {
		return config.flags?.identityAppConfig?.tenant === constants.TENANT_NABC;
	}

	// Renders the identity mini-app with the tag and config provided via the flags field
	public async authenticate(
		config: CustomNabCAuthConfig,
		retryCount: number = 0,
	): Promise<DafAuthToken<CustomNabCAuthConfig>> {
		try {
			return new Promise((res) => {
				const { dispatchEvent, addEventListener, removeEventListener } =
					getShellActions();
				dispatchEvent(constants.EVENT_LOGIN, {
					tag: config.flags.identityAppTag,
					appConfig: config.flags.identityAppConfig,
				});

				const cleanup = () => {
					document.removeEventListener(
						constants.KEY_DOWN,
						handleESCKeyEvent,
						false,
					);
					removeEventListener(
						constants.EVENT_USER_AUTHENTICATED_XV2,
						handleUserAuthenticatedEvent,
					);
					removeEventListener(
						constants.EVENT_USER_CANCELLED_XV2,
						handleUserCancelEvent,
					);
				};
				const handleUserAuthenticatedEvent = (token) => {
					cleanup();
					token && res({ ...token, config });
				};
				const handleUserCancelEvent = () => {
					cleanup();
					res({ tokenValue: '' });
				};
				const handleESCKeyEvent = (event) => {
					if (event.keyCode === 27) {
						cleanup();
						res({ tokenValue: '' });
					}
				};
				document.addEventListener(constants.KEY_DOWN, handleESCKeyEvent, false);
				addEventListener(
					constants.EVENT_USER_AUTHENTICATED_XV2,
					handleUserAuthenticatedEvent,
				);
				addEventListener(
					constants.EVENT_USER_CANCELLED_XV2,
					handleUserCancelEvent,
				);
			});
		} catch (e) {
			if (e?.response?.status && dafErrorMap[`${e.response.status}`]) {
				const error = new Error(dafErrorMap[`${e.response.status}`]);
				return this.handleDafError(error, config, retryCount);
			}
			throw new Error(DafAuthError.UNKNOWN_ERROR);
		}
	}
}
