import { ServicesConfig } from '@nab/x-types';

export const getDataSecurityConfig = (config): Partial<ServicesConfig> => {
	return {
		dataSecurity: {
			bc: {
				LibraryUrl: config?.bc?.LibraryUrl,
				configValue: config?.bc?.configValue,
			},
			gbd: {
				LibraryUrl: config?.gbd?.LibraryUrl,
				configValue: config?.gbd?.configValue,
			},
			iv: {
				LibraryUrl: config?.iv?.LibraryUrl,
				configValue: {
					IGL00_NAME:
						config?.iv?.configValue?.IGL00_NAME,
					loader: {
						uri_hook:
							config?.iv?.configValue?.loader
								?.uri_hook,
						version:
							config?.iv?.configValue?.loader
								?.version,
					},
				},
			},
			fhp: {
				LibraryUrl: config?.fhp?.LibraryUrl,
				configValue: {
					csId: config?.fhp?.configValue?.csId,
					cid: config?.fhp?.configValue?.cid,
					backUrl:
						config?.fhp?.configValue?.backUrl,
					rsaModulus:
						config?.fhp?.configValue?.rsaModulus,
					gafUrl:
						config?.fhp?.configValue?.gafUrl,
					forceFirstAlive:
						config?.fhp?.configValue
							?.forceFirstAlive,
					silentAlive:
						config?.fhp?.configValue?.silentAlive,
					cookiesDomain:
						config?.fhp?.configValue
							?.cookiesDomain,
				},
			},
		},
	};
};
