export const safeJsonParse = (input: string | Object) => {
	let res = {};

	try {
		res = typeof input === 'string' ? JSON.parse(input) : input;
	} catch (x) {
		// eslint-disable-next-line no-console
		console.warn(`Could not JSON.parse value`, input);
	}

	return res;
}
