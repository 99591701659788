import styled from 'styled-components';

export const ErrorScreenContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const ErrorBanner = styled.div`
	background-color: ${({ theme }) => theme.color.nabWhite};
	display: flex;
	flex-direction: column;
	padding: 2rem;
	gap: 1rem;
	text-align: center;
	align-items: center;

	p {
		max-width: 48ch;
	}
`;

export const IconWrapper = styled.div`
	text-align: center;
`;

export const ModalDataContainer = styled.div`
	margin-top: 64px;

	p {
		margin-bottom: 64px;
	}
`;
