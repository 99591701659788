import React, { useEffect, useRef, useState } from "react";
import { getShellActions, loadApp } from "@nab/x-runtime";
import { Modal } from "@nab/nui-react";
import constants from "../../util/constants";
import { StyledModalContent } from "./IdentityApp.style";
import { errorMessage } from "util/unknown-error";
import merge from "lodash.merge";

const IdentityApp: React.FunctionComponent<{ config }> = ({ config }) => {
  const appActions = getShellActions();
  const identityRootReference = useRef(null);
  const modelReference = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [identityAppConfig, setIdentityAppConfig] = useState(config);

  const miniappLoader = document.querySelector("mini-app-loader");

  const genIdentityAppTag = (tag: string) => {
    return {
      appMetaData: {
        id: tag,
        tag: tag,
        entryPoint: tag + ".js",
      },
    };
  };

  const handleLoginRequest = (event) => {
    setIdentityAppConfig(
      merge(
        identityAppConfig,
        { appConfig: event?.appConfig || {} },
        genIdentityAppTag(
          event?.identityAppTag ||
            event?.tag ||
            identityAppConfig.appMetaData.tag,
        ),
      ),
    );
    setShowModal(true);
  };

  const handleUserAuthenticatedEvent = async (data) => {
    if (data?.ssc) {
      const { user } = window.digitalData;
      window.digitalData.user = { ...user, ssc: data.ssc };
    }

    const authenticatedEvent = new Event(
      constants.EVENT_USER_AUTHENTICATED_XV2,
    );
    miniappLoader && miniappLoader.dispatchEvent(authenticatedEvent);
    if (identityAppConfig?.properties?.skipAuthOnRender) {
      // TODO: find context on this, doesn't exist on OSGI configs
      await appActions.authenticate();
    }
    setShowModal(false);
  };

  const handleUserCancelEvent = () => {
    setShowModal(false);
  };

  const handleESCKeyEvent = (event) => {
    if (event.keyCode === 27) {
      setShowModal(false);
    }
  };

  const displayMessageHandler = (event) => {
    appActions.dispatchEvent(constants.EVENT_CLEAR_USER_DATA);
    appActions.dispatchEvent(constants.EVENT_DISPLAY_MESSAGE, event.detail);
  };

  useEffect(() => {
    //Listening for login request
    appActions.addEventListener(constants.EVENT_LOGIN, handleLoginRequest);

    //Listening for user authenticated event
    appActions.addEventListener(
      constants.EVENT_USER_AUTHENTICATED_XV2,
      handleUserAuthenticatedEvent,
    );

    //Listening for user cancel event
    appActions.addEventListener(
      constants.EVENT_USER_CANCELLED_XV2,
      handleUserCancelEvent,
    );

    miniappLoader.addEventListener(
      constants.EVENT_DISPLAY_MESSAGE,
      displayMessageHandler,
    );

    //Listening for ESC Key event
    document.addEventListener(constants.KEY_DOWN, handleESCKeyEvent, false);
    return () => {
      //Unsubscribe for login request
      appActions.removeEventListener(constants.EVENT_LOGIN, handleLoginRequest);

      //Unsubscribe for user authenticated event
      appActions.removeEventListener(
        constants.EVENT_USER_AUTHENTICATED_XV2,
        handleUserAuthenticatedEvent,
      );

      //Unsubscribe for user cancel event
      appActions.removeEventListener(
        constants.EVENT_USER_CANCELLED_XV2,
        handleUserCancelEvent,
      );

      miniappLoader.removeEventListener(
        constants.EVENT_DISPLAY_MESSAGE,
        displayMessageHandler,
      );

      //Unsubscribe for ESC Key event
      document.removeEventListener(
        constants.KEY_DOWN,
        handleESCKeyEvent,
        false,
      );
    };
  }, []);

  useEffect(() => {
    let loaderPromise;

    if (showModal) {
      let loadAppConfig = {
        ...identityAppConfig,
        ...{ hostElement: identityRootReference.current },
      };

      const load = async () => {
        try {
          loaderPromise = loadApp(loadAppConfig);
          await loaderPromise;

          await appActions.dispatchEvent(constants.APP_SHELL_LOADED_EVENT, {
            appLoaded: true,
            appName: identityAppConfig.appMetaData.tag,
          });
        } catch (e) {
          setShowModal(false);
          await appActions.dispatchEvent(constants.APP_SHELL_LOADED_EVENT, {
            appLoaded: false,
            appName: identityAppConfig.appMetaData.tag,
            errorMessage: errorMessage(e),
          });
        }
      };
      load();
    }
    return () => {
      if (loaderPromise) {
        loaderPromise.then((app) => {
          app.unload();
        });
      }
    };
  }, [identityRootReference, showModal]);

  const shadowRoot = document.querySelector(
    identityAppConfig.appMetaData.tag,
  )?.shadowRoot;

  return (
    <div ref={modelReference}>
      {showModal && (
        <Modal id="shell-im" title="" rootNode={shadowRoot}>
          <StyledModalContent>
            <div ref={identityRootReference} />
          </StyledModalContent>
        </Modal>
      )}
    </div>
  );
};

export default IdentityApp;
