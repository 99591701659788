export const getBrandConfig = (brandConfig) => {
	return brandConfig?.brand
		? {
        brand: {
          id: brandConfig?.id,
          theme: brandConfig?.theme
        }
		  }
		: {};
};
