import constants from './constants';
import axios from 'axios';
import { KeepAlive } from '../keep-alive';
import { getShellActions } from '@nab/x-runtime';
import { getCachedTokens } from 'runtime/auth-token-cache-strategy/get-cached-tokens';
import { IOSGIConfig } from '../interfaces';

export const sessionKeepAlive = (config: IOSGIConfig) => {
	const heartbeat = async () => {
		const url: string = config.keepAliveConfig.endpoint + constants.OAUTH_KEEP_ALIVE_ENDPOINT;
		const tokens = await getCachedTokens(
			config.runtimeConfig.authConfig, config.runtimeConfig.authTokenCacheStrategy
		);
		for (const token of tokens) {
			try {
				await axios.get(url, {
					headers: {
						authorization: `bearer ${token}`,
					},
				});
			} catch {
				// error(appConfig, 'Heartbeat call failed');
			}
		}
	};
	return new KeepAlive({
		idleTime: config.keepAliveConfig.idleTime,
		heartbeatProvider: heartbeat,
		onUserInActive: () => {
			getShellActions().dispatchEvent(
				constants.APP_SHELL_KEEP_ALIVE_USER_INACTIVE,
			);
		},
	});
};
