import { removeUndefinedAttributes } from 'util/remove-undefined-attributes';
import { IOSGIConfig } from '../interfaces';

export const shellConfigs = (OSGIconfig: IOSGIConfig['shellConfig']) => {
	return removeUndefinedAttributes({
		abandonFormNotification: OSGIconfig?.abandonFormNotification,
		clearTokenOnInit: OSGIconfig?.clearTokenOnInit,
		contentFragment: OSGIconfig?.contentFragment,
		appConfig: OSGIconfig?.appConfig,
		renderLogin: OSGIconfig?.renderLogin,
	});
};
