import * as yup from 'yup';

export const safeSchemaParse = <T>(object: any, schema: yup.AnyObjectSchema): T => {
	try {
		const config: T = schema.validateSync(object, { strict: true, abortEarly: false, recursive: true });
		return config;
	} catch (x) {
		if (x instanceof yup.ValidationError) {
			console.warn(`nab-shell config validation failed: ${x.errors.join('. ')}`);
		} else {
			console.warn(x);
		}

		// Return the original object to prevent validation false positives from breaking stuff
		return object;
	}
};
