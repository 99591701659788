import {
	DafInMemoryCacheStrategy,
	AuthToken,
	DafAuthConfig,
} from '@nab/x-runtime';
import constants from '../../util/constants';
import { INabDafInMemoryCacheStrategy } from './nab-daf-in-memory.interface';

class NabDafInMemoryCacheStrategy
	extends DafInMemoryCacheStrategy
	implements INabDafInMemoryCacheStrategy
{
	public async getToken(config) {
		// to retrieve the authenticated token
		if (sessionStorage.getItem(constants.BEARER_TOKEN) && config.isSeed) {
			let token: AuthToken<DafAuthConfig> = new (class
				implements AuthToken<DafAuthConfig>
			{
				config: DafAuthConfig;
				expires: number;
				id: string;
				parentId: string;
				tokenValue: string;
			})();
			if (sessionStorage.getItem(constants.AUTHENTICATED_2FA_TOKEN)) {
				token.tokenValue = sessionStorage.getItem(
					constants.AUTHENTICATED_2FA_TOKEN,
				);
			} else {
				token.tokenValue = sessionStorage.getItem(constants.BEARER_TOKEN);
			}
			return token;
		} else {
			const cacheEntry = this.findTokenByConfig(config);
			if (cacheEntry) {
				if (
					!cacheEntry.token.expires ||
					cacheEntry.token.expires >= Date.now() + this.minTokenLifespan
				) {
					return cacheEntry.token;
				}
				this.removeCacheEntry(cacheEntry);
			}
		}
		return undefined;
	}

	public async addToken(token: AuthToken<DafAuthConfig>): Promise<void> {
		// Note we search by config rather than value here as the purpose of the addToken operation may be to update the tokenValue
		const cacheEntry = this.findTokenByConfig(token.config);
		if (cacheEntry) {
			/**
			 * If a token with matching config is already cached and its value differs then purge it.
			 * We want the fresh token to be returned from cache next time we ask for a token matching this config
			 */
			if (cacheEntry.token.tokenValue !== token.tokenValue) {
				this.removeCacheEntry(cacheEntry);
			} else {
				/**
				 * If cached token exists and value matches, then update all other metadata associated with the token.
				 * Perhaps its lifespan/expiry has been extended...
				 */
				cacheEntry.token = {
					...token,
				};
				return;
			}
		}
		let parentTokenValue: string;
		// Link the token to its parent token so we can purge it when the parent is purged from cache
		if (token.parentId) {
			const parentCacheEntry = this.cache.find(
				(ce) => ce.token.id === token.parentId,
			);
			parentTokenValue = parentCacheEntry && parentCacheEntry.token.tokenValue;
		}
		this.clearTokens();
		this.cache.unshift({
			token,
			parentTokenValue,
		});
	}

	public async getActorToken(config) {
		const cacheEntry = this.findTokenByConfig(config);
		if (cacheEntry) {
			if (
				!cacheEntry.token.expires ||
				cacheEntry.token.expires >= Date.now() + this.minTokenLifespan
			) {
				return cacheEntry.parentTokenValue;
			}
			this.removeCacheEntry(cacheEntry);
		}
		return undefined;
	}

	/**
	 * Remove token and all of its child tokens from the cache
	 */
	public async removeToken(token) {
		if (token.config?.isSeed) {
			window.sessionStorage.removeItem(constants.BEARER_TOKEN);
			window.sessionStorage.removeItem(constants.AUTHENTICATED_2FA_TOKEN);
		}
		const cacheEntry = this.findTokenByValue(token.tokenValue);
		if (cacheEntry) {
			this.removeCacheEntry(cacheEntry);
		}
	}

	/**
	 *
	 * @param ignoreShellToken {bool} flag to ignore shell tokens
	 */
	public clearTokens(ignoreShellToken: boolean = true) {
		const tokensToClear = this.cache.filter(
			(cachedToken) => !(ignoreShellToken && cachedToken.token.config?.isSeed),
		);
		tokensToClear.forEach(({ token }) => {
			this.removeToken(token);
		});
	}
}
export default NabDafInMemoryCacheStrategy;
