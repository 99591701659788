/**
 * KeepAlive implementation copied from NAB-X and modified for our purposes
 */

export interface IKeepAliveOptions extends IKeepAliveBaseOptions {
	heartbeatProvider: () => void;
}

export interface IKeepAliveBaseOptions {
	idleTime: number;
	events?: string[];
	container?: HTMLElement;
	earlyCheckFactorForHeartbeat?: number;
	onUserInActive?: () => void;
}

export interface IKeepAlive {
	init: () => void;
	destroy: () => void;
	IsUserActive: boolean;
}

export abstract class BaseKeepAlive implements IKeepAlive {
	/**
	 *  Overridden Properties
	 */

	/**
	 * Indicates the defaults that will be used to start the keep alive.
	 */
	protected defaultOptions: IKeepAliveBaseOptions = {
		/**
		 * The Idle Time used here is based on the Stargaze defaults for idle timeout (5 minutes).
		 */
		idleTime: 300,

		/**
		 * Indicates the default early check factor, that will be used to perform the check before the idle time is expired.
		 */
		earlyCheckFactorForHeartbeat: 0.75,

		/**
		 * Indicates the default events, that will be used to monitor users active status.
		 */
		events: ['mousemove', 'mousedown', 'keypress', 'touchstart', 'touchmove']
	};

	protected heartbeatIntervalId: number;

	protected isUserActive: boolean = false;

	/**
	 * Public Properties
	 */

	/**
	 * Exposes the boolean that will inform where the user is active or not
	 */
	public get IsUserActive(): boolean {
		return this.isUserActive;
	}

	constructor(protected options: Partial<IKeepAliveBaseOptions>) {
		this.options = { ...this.defaultOptions, ...options };
	}

	protected inactivateUser(): void {
		this.isUserActive = false;
	}

	protected keepUserActive(): void {
		this.isUserActive = true;
	}

	protected startHeartBeatTimeOut(): void {
		const heartbeatIdleTimer = Math.round(this.options.idleTime * 1000 * this.options.earlyCheckFactorForHeartbeat);

		// Had to bind the this here as we are following inheritance and use of arrow functions cause reference issues.
		// But the event handler works on the container context and the this gets overridden.
		// `any` is required for this code when used server side node js returns a TimeOut type && browser returns number,
		// but still can be used as the same way to clear the timer.
		this.heartbeatIntervalId = setInterval(this.heartbeatCalls.bind(this), heartbeatIdleTimer) as any;
	}

	/**
	 * Abstract methods
	 */

	/**
	 * Function used to define the heartbeat calls made by the timer job.
	 * This method will be provided as the input to the event listener.
	 */
	protected abstract heartbeatCalls(): void;

	/**
	 * Function used to define the events that needs to be registered for listening and determining that the user is active.
	 * The method has been extracted as the events setup are different for Web vs Server based code.
	 */
	protected abstract setupEvents(): void;

	/**
	 * Function used to destroy the events that were to be registered, when the keep alive functionality needs to be destroyed.
	 * The method has been extracted as the events destruction are different for Web vs Server based code.
	 */
	protected abstract destroyEvents(): void;

	/**
	 * @function destroy
	 * @description
	 * Destroys the time jobs that were initialized for the heart beat function.
	 * check if onUserInActive exists and is a function,
	 * then call onUserInActive for feedback if the user is in-active
	 */
	public destroy() {
		clearInterval(this.heartbeatIntervalId);
		this.destroyEvents();
		const { onUserInActive } = this.options;
		if (onUserInActive && typeof onUserInActive === 'function') {
			onUserInActive();
		}
	}

	/**
	 * Init:
	 * Initializes the keep alive timers based on the options provided
	 */
	public init() {
		this.setupEvents();
		this.startHeartBeatTimeOut();
	}
}

export class KeepAlive extends BaseKeepAlive implements IKeepAlive {
	// Keeping this for backwards compatibility as not sure if this was used explicitly in pure JS functions
	private earlyCheckFactorForHeartbeat: number = 0.75;

	// Keeping this for backwards compatibility as not sure if this was used explicitly in pure JS functions
	private defaultEvents: string[] = ['mousemove', 'mousedown', 'keypress', 'touchmove'];

	/**
	 * time out based on the idle timeout of the token
	 */
	protected defaultOptions: IKeepAliveOptions = {
		idleTime: 120,
		earlyCheckFactorForHeartbeat: this.earlyCheckFactorForHeartbeat,
		events: this.defaultEvents,
		heartbeatProvider: () => {
			return Promise.reject(new Error('Heart beat function not passed in. Token will not be kept alive'));
		}
	};

	/**
	 * gets the heartbeat interval id created by starting the Interval job for heart beat calls
	 */
	public get HeartbeatIntervalId(): number {
		return this.heartbeatIntervalId;
	}

	/**
	 * exposes the boolean that will inform where the user is active or not
	 */
	public get IsUserActive(): boolean {
		return this.isUserActive;
	}

	/**
	 * Exposes the default factor added to the time required for inactivity
	 * This is required for the heart beat interval to be less that the inactivity timeout.
	 */
	public get EarlyCheckFactorForHeartbeat(): number {
		return this.options.earlyCheckFactorForHeartbeat;
	}

	/**
	 * Gets the inactivity timeout in milliseconds
	 */
	public get InactiveTimeout(): number {
		return this.options.idleTime * 1000;
	}

	constructor(protected options: IKeepAliveOptions) {
		// We have to override the constructor as the overridden properties/variables like defaultOptions
		// gets read from this class and not base class
		super(options);
		this.options = { ...this.defaultOptions, ...options };
	}

	protected heartbeatCalls() {
		const { heartbeatProvider } = this.options;
		if (this.IsUserActive && heartbeatProvider) {
			heartbeatProvider();
			this.inactivateUser();
		} else {
			this.destroy();
		}
	}

	protected startHeartBeatTimeOut(): void {
		const heartbeatIdleTimer = Math.round(this.InactiveTimeout * this.EarlyCheckFactorForHeartbeat);
		// had to bind the this here as we are following inheritance and use of arrow functions cause reference issues.
		// But the event handler works on the container context and the this gets overridden
		this.heartbeatIntervalId = window.setInterval(this.heartbeatCalls.bind(this), heartbeatIdleTimer);
	}

	protected setupEvents(): void {
		const { container = window, events } = this.options;
		events.forEach((event: string) => {
			// had to bind the this here as we are following inheritance and use of arrow functions cause reference issues.
			// But the event handler works on the container context and the this gets overridden
			container.addEventListener(event, this.keepUserActive.bind(this), false);
		});
	}

	protected destroyEvents(): void {
		const { container = window, events } = this.options;
		events.forEach((event: string) => {
			// had to bind the this here as we are following inheritance and use of arrow functions cause reference issues.
			// But the event handler works on the container context and the this gets overridden
			container.removeEventListener(event, this.keepUserActive.bind(this), false);
		});
	}
}
