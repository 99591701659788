import axios from 'axios';

/**
 * @desc
 * Retrieves a mini-app's content fragment using the provided path.
 *
 * @param  {String}  path  Path to the servlet
 * @return {String}        JSON string of the content fragment
 */
export const retrieveJson = async (path: string) => {
	try {
		const response = await axios.get(path);
		return response.data;
	} catch (e) {
		console.error('Unable to retrieve json object at path', path);
	}
	return {};
};
