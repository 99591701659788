import React from 'react';

const ErrorIcon: React.FunctionComponent = () => {
    return (
        <>
            <svg width="120px" height="42px" viewBox="0 0 120 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <title>Icons / Empty states / System error</title>
                <desc>Created with Sketch.</desc>
                <defs>
                    <polygon id="path-1" points="0.000101785714 0.3774 59.0734768 0.3774 59.0734768 35 0.000101785714 35"></polygon>
                    <polygon id="path-3" points="0.635244643 0.3774 48.8948036 0.3774 48.8948036 27.7554 0.635244643 27.7554"></polygon>
                </defs>
                <g id="Icons-/-Empty-states-/-System-error" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Group-7" transform="translate(3.000000, 3.000000)">
                        <g id="Group-3" transform="translate(0.000000, 0.622600)">
                            <mask id="mask-2" fill="white">
                                <use xlinkHref="#path-1"></use>
                            </mask>
                            <g id="Clip-2"></g>
                            <path d="M57.5466911,10.3604 C58.3894768,10.3604 59.0734768,9.6894 59.0734768,8.8604 C59.0734768,8.0314 58.3894768,7.3604 57.5466911,7.3604 L52.4553696,7.3604 L52.4553696,4.9524 C52.4553696,2.4294 50.3657089,0.3774 47.7986732,0.3774 L41.0004054,0.3774 C33.8336732,0.3774 27.9138161,5.7214 27.1514411,12.5664 L18.4711554,12.5664 C14.5910839,12.5664 11.4326732,15.6684 11.4326732,19.4824 L11.4326732,28.0844 C11.4326732,30.2434 9.64429821,32.0004 7.44674464,32.0004 L1.5268875,32.0004 C0.684101786,32.0004 0.000101785714,32.6714 0.000101785714,33.5004 C0.000101785714,34.3284 0.684101786,35.0004 1.5268875,35.0004 L7.44674464,35.0004 C11.3288518,35.0004 14.4862446,31.8984 14.4862446,28.0844 L14.4862446,19.4824 C14.4862446,17.3224 16.2736018,15.5664 18.4711554,15.5664 L30.1215482,15.5664 L30.1215482,14.0664 C30.1215482,8.1724 35.0021732,3.3774 41.0004054,3.3774 L47.7986732,3.3774 C48.6821732,3.3774 49.4017982,4.0844 49.4017982,4.9524 L49.4017982,23.1804 C49.4017982,24.0484 48.6821732,24.7554 47.7986732,24.7554 L41.0004054,24.7554 C38.8211732,24.7554 36.7172625,24.1244 34.9197268,22.9304 C34.2204589,22.4654 33.2718161,22.6474 32.7995304,23.3334 C32.3272446,24.0204 32.5104589,24.9524 33.2097268,25.4164 C35.5141554,26.9464 38.2084232,27.7554 41.0004054,27.7554 L47.7986732,27.7554 C50.3657089,27.7554 52.4553696,25.7034 52.4553696,23.1804 L52.4553696,20.7724 L57.5466911,20.7724 C58.3894768,20.7724 59.0734768,20.1004 59.0734768,19.2724 C59.0734768,18.4434 58.3894768,17.7724 57.5466911,17.7724 L52.4553696,17.7724 L52.4553696,10.3604 L57.5466911,10.3604 Z" id="Fill-1" fill="#C20000" mask="url(#mask-2)"></path>
                        </g>
                        <g id="Group-6" transform="translate(64.125000, 0.622600)">
                            <mask id="mask-4" fill="white">
                                <use xlinkHref="#path-3"></use>
                            </mask>
                            <g id="Clip-5"></g>
                            <path d="M47.3681196,12.5664 L25.9391732,12.5664 C25.1767982,5.7214 19.2569411,0.3774 12.0902089,0.3774 L5.29092321,0.3774 C2.7238875,0.3774 0.635244643,2.4294 0.635244643,4.9524 L0.635244643,23.1804 C0.635244643,25.7034 2.7238875,27.7554 5.29092321,27.7554 L12.0902089,27.7554 C15.4308161,27.7554 18.6614946,26.5764 21.1878161,24.4344 C21.8260125,23.8934 21.8972625,22.9454 21.3466018,22.3184 C20.7959411,21.6924 19.8330482,21.6214 19.1917982,22.1634 C17.2212268,23.8354 14.6979589,24.7554 12.0902089,24.7554 L5.29092321,24.7554 C4.40742321,24.7554 3.68881607,24.0484 3.68881607,23.1804 L3.68881607,4.9524 C3.68881607,4.0844 4.40742321,3.3774 5.29092321,3.3774 L12.0902089,3.3774 C18.0884411,3.3774 22.9690661,8.1724 22.9690661,14.0664 L22.9690661,15.5664 L47.3681196,15.5664 C48.2109054,15.5664 48.8949054,14.8954 48.8949054,14.0664 C48.8949054,13.2374 48.2109054,12.5664 47.3681196,12.5664" id="Fill-4" fill="#C20000" mask="url(#mask-4)"></path>
                        </g>
                    </g>
                </g>
            </svg>
        </>
    );
};
export default ErrorIcon;

