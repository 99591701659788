import { ServicesConfig } from '@nab/x-types';

export const getFraudInteractionConfig = (config): Partial<ServicesConfig> => {
	return {
		interaction: {
			endpoint: config?.endpoint,
			sessionEndpoint: config?.sessionEndpoint,
		},
	};
};
