import merge from 'lodash.merge';
import { removeUndefinedAttributes } from 'util/remove-undefined-attributes';
import { sanitiseAuthConfig } from './auth-config/auth-config.config';
import { getAuthStrategiesRuntime } from './auth-strategies/auth-strategies';
import { sanitiseAuthStrategiesConfig } from './auth-strategies/auth-strategies.config';
import { getTokenCacheStrategy } from './auth-token-cache-strategy';
import { getServicesConfig } from './services-config';
import { getSessionIdConfig } from './session-id/session-id.config';
import { getUserConfig } from './user-config/user-config.config';
import { getBrandConfig } from './brand/brand.config';
import { IOSGIConfig } from '../interfaces';

export const sanitiseRuntimeConfig = (config: IOSGIConfig['runtimeConfig']): IOSGIConfig['runtimeConfig'] => {
	return merge(
		sanitiseAuthConfig(config.authConfig),
		getAuthStrategiesRuntime(
			sanitiseAuthStrategiesConfig(config.authStrategiesConfig),
		),
		getTokenCacheStrategy(),
		getServicesConfig(config.servicesConfig),
		removeUndefinedAttributes(getSessionIdConfig(config.sessionId)),
		removeUndefinedAttributes(getUserConfig(config.userConfig)),
		removeUndefinedAttributes(getBrandConfig(config.brand)));
};
