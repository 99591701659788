import constants from './constants';

const getUserContext = () => {
	return navigator.cookieEnabled &&
		window.sessionStorage.getItem(constants.USERCONTEXT)
		? window.sessionStorage.getItem(constants.USERCONTEXT)
		: constants.DEFAULT_USERCONTEXT_VALUE;
};

export const userIsInternal = () => {
	return getUserContext() === constants.USERCONTEXT_INTERNAL;
};

export const getContextObj = (obj) => {
	return userIsInternal() ? obj?.internal : obj.external;
};
