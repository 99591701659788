import merge from 'lodash.merge';
import { getAnalyticsConfig } from './analytics.config';
import { getAppDConfig } from './appd.config';
import { getDataSecurityConfig } from './data-security.config';
import { getFraudInteractionConfig } from './fraud.config';
import { getHistoryConfig } from './history.config';
import { getFlagStrategyConfig } from './launch-darkly.config';
import { getInteractionPrefixConfig } from './interactionPrefix.config';
import { removeUndefinedAttributes } from 'util/remove-undefined-attributes';

export const getServicesConfig = (config) => {
	return {
		servicesConfig: merge(
			getAnalyticsConfig(),
			getAppDConfig(config.rum),
			removeUndefinedAttributes(getDataSecurityConfig(config.dataSecurity)),
			removeUndefinedAttributes(getFraudInteractionConfig(config.interaction)),
			removeUndefinedAttributes(getHistoryConfig(config.history)),
			getFlagStrategyConfig(config.flags),
			removeUndefinedAttributes(getInteractionPrefixConfig(config.interactionPrefix)),
		),
	};
};
