import { ServicesConfig } from '@nab/x-types';

export const getHistoryConfig = (config): Partial<ServicesConfig> => {
	return {
		history: {
			routingBasePath: config?.routingBasePath,
			suppressRouting: config?.suppressRouting,
		},
	};
};
