import { IParams } from '../interfaces';

export const getParams = (): IParams => {
	const urlSearchParams = new URLSearchParams(window.location.search);
	const params: IParams = {};
	for (let key of urlSearchParams.keys()) {
		params[key] = urlSearchParams.getAll(key);
	}
	return params;
};
