import { getShellActions, loadApp } from '@nab/x-runtime';
import { retrieveJson } from '../../util/retrieve-json';
import { errorMessage } from 'util/unknown-error';
import merge from 'lodash.merge';
import constants from '../../util/constants';

export const loadEmbeddedApp = async (
	config: any,
	rootElement: HTMLElement,
) => {
	if (config?.appConfig?.cf) {
		config.appConfig.cf = await retrieveJson(config.appConfig.cf);
	}

	if (config?.appConfig?.experienceFragments) {
		config.appConfig.experienceFragments = await Promise.all(
			config.appConfig.experienceFragments.map(async (path) => await retrieveJson(path))
		)
	}
	const shellActions = getShellActions();

	try {
		await loadApp(
			merge(config, {
				hostElement: rootElement,
			}),
		);

		await shellActions.dispatchEvent(constants.APP_SHELL_LOADED_EVENT, {
			appLoaded: true,
			appName: config.appMetaData.tag,
		});
	} catch (e) {
		await shellActions.dispatchEvent(constants.APP_SHELL_LOADED_EVENT, {
			appLoaded: false,
			appName: config.appMetaData.tag,
			errorMessage: errorMessage(e),
		});
	}
};
