import { DafAuthConfig } from '@nab/x-runtime';

import NabDafInMemoryCacheStrategy from './nab-daf-in-memory';
import constants from '../../util/constants';

/**
 * Gets the tokens(seed / actor token and authenticated token) stored in the cache
 * @returns {Promise<string[]>} tokens in an array
 */
export const getCachedTokens = async (
	authConfig: DafAuthConfig,
	authTokenCache: NabDafInMemoryCacheStrategy,
): Promise<string[]> => {
	const tokens: string[] = [];
	try {
		const actorToken = await authTokenCache.getActorToken(authConfig);
		if (actorToken) {
			tokens.push(actorToken);
		}

		const appToken = await authTokenCache.getToken(authConfig);
		if (appToken) {
			tokens.push(appToken.tokenValue);
		}

		const authenticatedToken = window.sessionStorage.getItem(
			constants.BEARER_TOKEN,
		);
		if (authenticatedToken) {
			tokens.push(authenticatedToken);
		}

		const authenticatedToken_2FA = window.sessionStorage.getItem(
			constants.AUTHENTICATED_2FA_TOKEN,
		);
		if (authenticatedToken_2FA) {
			tokens.push(authenticatedToken_2FA);
		}

		return tokens;
	} catch (e) {
		return Promise.reject(e);
	}
};
